import React from 'react'
import './success.style.scss'

const Success = () => {
  return (
    <div className='success'>
      <h1>Subscription Confirmed!</h1>
    </div>
  )
}

export default Success
