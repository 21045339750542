export const data = {
  cryptoS: {
    prices: {
      monthly: ['price_1KU49CGDws3elXsYK5V3Zh5p', '49.99'],
      three: ['price_1KU4AcGDws3elXsYJv6rPveO', '119.99'],
      yearly: ['price_1KU4C6GDws3elXsYpuv7UjSp', '399.99'],
    },
    data: [
      'Real Time Crypto & Stocks Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
  crypto: {
    prices: {
      monthly: ['price_1KU451GDws3elXsYyS4ogQqs', '25.99'],
      three: ['price_1KU465GDws3elXsYNfRYCgHP', '65.99'],
      yearly: ['price_1KU47qGDws3elXsYFiCmJCFE', '249.99'],
    },
    data: [
      'Real Time Crypto Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
  cryptoO: {
    prices: {
      monthly: ['price_1KU4H5GDws3elXsY1SAj0ORk', '49.99'],
      three: ['price_1KU4IMGDws3elXsYWDH4DCKo', '119.99'],
      yearly: ['price_1KU4JmGDws3elXsY5i2Z2mIo', '399.99'],
    },
    data: [
      'Real Time Crypto & Options Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
  cryptoSO: {
    prices: {
      monthly: ['price_1KU3s9GDws3elXsYRtOayHZA', '59.99'],
      three: ['price_1KU3tWGDws3elXsYlipZ7K4l', '129.99'],
      yearly: ['price_1KU3v0GDws3elXsYYWGwfzz5', '484.99'],
    },
    data: [
      'Real Time Crypto & Bundle Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
}
