import React, { useState, useRef, useEffect } from 'react'

import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import firebase, {
  auth,
  createUserProfileDocument,
} from '../../firebase/firebase.utils'
import Button from '../button/button.component'
import Input from '../input/input.component'
import './signin.style.scss'
import ClipLoader from 'react-spinners/ClipLoader'

const Signin = () => {
  const [recaptcha, setRecaptcha] = useState(null)
  const element = useRef(null)

  useEffect(() => {
    const verifier: any = new firebase.auth.RecaptchaVerifier(element.current, {
      size: 'invisible',
    })
    if (!recaptcha) {
      verifier.verify().then(() => setRecaptcha(verifier))
    }
  })

  return (
    <div className='signin'>
      <form
        onSubmit={(e) => e.preventDefault()}
        className='phone-form'
        noValidate={true}
      >
        {recaptcha ? (
          <PhoneNumberVerification recaptcha={recaptcha} />
        ) : (
          <ClipLoader color={'#26667a'} loading={!recaptcha} size={80} />
        )}
        <div ref={element}></div>
      </form>
    </div>
  )
}

const PhoneNumberVerification: any = ({ recaptcha }: any) => {
  const [digits, setDigits] = useState({ value: '', cc: '' })
  const [confirmationResult, setConfirmationResult] = useState<any>(null)
  const [code, setCode] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [loader, setLoader] = useState(false)
  const phoneNumber = '+' + digits.cc + digits.value

  const signInWithPhoneNumber = async () => {
    try {
      setLoader(true)
      setConfirmationResult(
        await auth.signInWithPhoneNumber(phoneNumber, recaptcha)
      )
      setLoader(false)
    } catch (err) {
      setLoader(false)
      setError('invalid Phone number')
    }
  }

  const verifyCode = async () => {
    try {
      setLoader(true)
      const result = await confirmationResult.confirm(code)
      createUserProfileDocument(result.user)
      console.log(result)
      setLoader(false)
    } catch (err) {
      setError('Invalid Code')
      setLoader(false)
    }
  }
  return (
    <>
      <h1 className='title'>Sign in</h1>
      <IntlTelInput
        value={digits.value}
        onChange
        preferredCountries={['us']}
        onPhoneNumberChange={(e: any, value: any, obj: any) =>
          setDigits({ value: value, cc: obj.dialCode })
        }
      />
      {confirmationResult ? (
        <Input
          type='text'
          value={code}
          onChange={(e) => setCode(e.target.value)}
          id='code'
          name='code'
        />
      ) : (
        <div className='dummy'>We verify your phone number to sign in</div>
      )}
      {confirmationResult ? (
        <Button click={verifyCode} types='submit'>
          {!loader ? (
            'Verify'
          ) : (
            <ClipLoader color={'#fff'} loading={loader} size={30} />
          )}
        </Button>
      ) : (
        <Button click={signInWithPhoneNumber} types='submit'>
          {!loader ? (
            'Sign in'
          ) : (
            <ClipLoader color={'#fff'} loading={loader} size={30} />
          )}
        </Button>
      )}
    </>
  )
}

export default Signin
