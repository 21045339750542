import React, { useEffect, useState, useContext } from 'react'
import Button from '../../components/button/button.component'
import { AuthContext } from '../../context/context'
import { fetchFromAPI } from '../../firebase/helper'
import ClipLoader from 'react-spinners/ClipLoader'
import './profile.style.scss'

const Profile = () => {
  const { user } = useContext(AuthContext)
  const [subscriptions, setSubscriptions] = useState<any>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getSubscription()
  }, [user])

  // fetch current subs from api
  const getSubscription = async () => {
    if (user) {
      const subs = await fetchFromAPI('subscriptions', { method: 'GET' })
      setSubscriptions(subs)
    }
  }

  // cancel a subscriptions
  const cancel = async (id: string) => {
    setLoading(true)
    try {
      await fetchFromAPI('subscriptions/' + id, { method: 'PATCH' })
      // bring up a alert msg
      await getSubscription()
      alert('Your Subscription will be cancelled at the end of the period')
    } catch (err) {
      alert(err.message)
    }
    setLoading(false)
  }

  return (
    <div className='profile'>
      <h1>Your Profile</h1>
      <div className='profile-container'>
        <div className='content'>
          {subscriptions ? (
            subscriptions.map((sub: any) => (
              <>
                <div key={sub.id} className='sub'>
                  <div className='sub-type'>
                    <h1>Your Current Subscription</h1>
                    <h3>{sub.id}</h3>
                  </div>
                  <div className='due'>
                    <h1>Next payment </h1>
                    <h3>for ${sub.plan.amount / 100} due </h3>
                    <h3 style={{ width: '60%' }}>
                      {new Date(sub.current_period_end * 1000).toUTCString()}
                    </h3>
                  </div>
                </div>
                <Button types='submit' click={() => cancel(sub.id)}>
                  Cancel
                  <ClipLoader color={'#fff'} loading={loading} size={30} />
                </Button>
              </>
            ))
          ) : (
            <span>No Records Found!</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Profile
