import React from 'react'
import './App.css'
import Checkout from './pages/checkout/checkout.component'
import { Route, Switch } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import AuthProvider from './context/context'
import Main from './pages/main/main.component'
import Premium from './pages/premium/premium.component'
import ProtectedRoute from './components/protectedRoute/protectedRoute.component'
import Profile from './pages/profile/profile.component'
import Navbar from './components/navbar/navbar.component'
import SigninPage from './pages/signin/signinPage.component'
import Crypto from './pages/crypto/crypto.component'
import Success from './pages/success/success.component'
import Golden from './pages/golden/golden.component'
import Book from './pages/book/book.component'

function App() {
  const stripePromise = loadStripe(
    'pk_live_51IGxBqGDws3elXsYHZ6hMx0PWrpJlUozAwtMUXPKX8CqpE7djAvMS49iiLNnpJuwUurCA40GFlaMoNW47Xpn7gyR00v0y34SBz'
  )

  return (
    <div className='App'>
      <AuthProvider>
        <Navbar />
        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/crypto' component={Crypto} />
          <Route exact path='/golden' component={Golden} />
          <Route exact path='/premium' component={Premium} />
          <Route exact path='/success' component={Success} />
          <Route exact path='/books' component={Book} />
          <ProtectedRoute
            pro={false}
            exact
            path='/profile'
            component={Profile}
          />
          <Route exact path='/signin' component={SigninPage} />
          <Elements stripe={stripePromise}>
            <Route exact path='/checkout' component={Checkout} />
          </Elements>
        </Switch>
      </AuthProvider>
    </div>
  )
}

export default App
