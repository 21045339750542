import React from 'react'

import './input.style.scss'
import { IoIosLock } from 'react-icons/io'
import { IoIosMail } from 'react-icons/io'
import { IoMdPerson } from 'react-icons/io'
import { IoMdContact } from 'react-icons/io'
import { IoMdCall } from 'react-icons/io'
import { IoIosSearch } from 'react-icons/io'

interface props {
  id: string
  type: string
  errors?: string | undefined
  extraCont?: string
  extra?: string
  touched?: boolean | undefined
  name: string
  value: string
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  onBlur?: (e: React.FocusEvent<any>) => void | undefined
}

const Input: React.FC<props> = ({
  errors,
  extraCont,
  extra,
  touched,
  name,
  value,
  ...otherProps
}) => {
  let logo = null

  if (name === 'email') {
    logo = <IoIosMail className='logo' />
  } else if (name === 'password') {
    logo = <IoIosLock className='logo' />
  } else if (name === 'firstName') {
    logo = <IoMdContact className='logo' />
  } else if (name === 'lastName') {
    logo = <IoMdContact className='logo' />
  } else if (name === 'confirmPassword') {
    logo = <IoIosLock className='logo' />
  } else if (name === 'username') {
    logo = <IoMdPerson className='logo' />
  } else if (name === 'phone') {
    logo = <IoMdCall className='logo' />
  } else if (name === 'search') {
    logo = <IoIosSearch className='logo' />
  }
  return (
    <div className='input'>
      <div className='container'>
        {logo}
        <input
          {...otherProps}
          value={value}
          className={`${errors && touched && 'error'} ${
            name === 'search' ? 'search' : ''
          } custom_input ${extra}`}
          required={true}
          placeholder={name === 'phone' ? '+180735xxxxx' : name.toUpperCase()}
          formNoValidate={true}
        />
        {/* <span className='placeholder'>{name}</span> */}
      </div>
      {errors && touched && <div className='input-feedback'>{errors}</div>}
    </div>
  )
}

export default Input
