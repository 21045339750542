import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyCzXrf-YuJYHRwDXKtGAY87WlpsWZHTP3A',
  authDomain: 'wsbsms.firebaseapp.com',
  projectId: 'wsbsms',
  storageBucket: 'wsbsms.appspot.com',
  messagingSenderId: '249404118792',
  appId: '1:249404118792:web:5c263aa2adfd9db3ac3ebd',
  measurementId: 'G-VQR0L1YXGY',
}

export const createUserProfileDocument = async (
  userAuth: firebase.User | null
) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const { phoneNumber }: any = userAuth
    const createdAt = new Date()
    try {
      await userRef.set({
        phone: phoneNumber,
        createdAt,
      })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }
  return userRef
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

export default firebase
