import React, { useState } from 'react'
import Card from '../../components/card/card.component'
import Modal from '../../components/modal/modal.component'
import './crypto.style.scss'
import { data } from './data'

const Crypto = () => {
  const [modal, setModal] = useState({ title: '', data: {}, toggle: false })

  return (
    <div className='crypto'>
      <h1 className='main-title'>Real Time Crypto SMS Premium Service</h1>
      {modal.toggle && (
        <Modal
          data={modal.data}
          title={modal.title}
          setModalActive={setModal}
        />
      )}
      <div className='card-container'>
        <Card title='Crypto' data={data.crypto} modalStatus={setModal} />
        <Card
          title='Crypto'
          extra='+ stocks'
          data={data.cryptoS}
          modalStatus={setModal}
        />
        <Card
          title='Crypto'
          extra='+ options'
          data={data.cryptoO}
          modalStatus={setModal}
        />
        <Card
          title='Crypto'
          extra='+ Bundle'
          data={data.cryptoSO}
          modalStatus={setModal}
        />
      </div>
    </div>
  )
}

export default Crypto
