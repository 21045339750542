import React, { useContext } from "react"
import { Route, Redirect } from "react-router"
import { AuthContext } from "../../context/context"

interface protectedProps {
  component: React.FC
  pro: boolean
  exact: boolean
  path: string
}

const ProtectedRoute: React.FC<protectedProps> = ({
  component: Component,
  pro,
  ...rest
}: any) => {
  const { user, plans } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          pro ? (
            plans && plans.length > 0 ? (
              <Component {...routeProps} />
            ) : (
              <Redirect to={"/product"} />
            )
          ) : (
            <Component {...routeProps} />
          )
        ) : (
          <Redirect to={"/signin"} />
        )
      }
    />
  )
}

export default ProtectedRoute
