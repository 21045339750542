import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Signin from '../../components/signin/signin.component'
import './signinPage.style.scss'
import { AuthContext } from '../../context/context'

const SigninPage: React.FC = () => {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  if (user) {
    history.push('/')
  }
  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [user])
  return (
    <div className='signin-page'>
      <Signin />
    </div>
  )
}

export default SigninPage
