import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../button/button.component'
import './modal.style.scss'

interface props {
  setModalActive: React.Dispatch<
    React.SetStateAction<{
      title: string
      data: {}
      toggle: boolean
    }>
  >
  data: any
  title: string
  book?: boolean
}

const Modal: React.FC<props> = ({ setModalActive, data, title, book }) => {
  const history = useHistory()

  return (
    <div className='modal'>
      <div
        className='background'
        onClick={() =>
          setModalActive((p) => {
            return { ...p, toggle: false }
          })
        }
      />
      <div className='content'>
        <div className='main-cont'>
          <div className='title'>
            <h1>{title}</h1>
            <span>{book ? 'Exclusive Book' : 'TEXT Messages'}</span>
          </div>
          <div className='content-data'>
            <ul>
              {data.data.map((item: any, index: any) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        </div>
        <div className='price-cont'>
          {book ? (
            <div className='prices'>
              <div className='price'>
                <span className='dollar'>$</span>
                <h1 className='tag'>{`${data.prices.day[1]}`}</h1>
                <span className='time'>once</span>
                <Button
                  types='submit'
                  click={() =>
                    history.push(`/checkout?id=${data.prices.day[0]}`)
                  }
                >
                  BUY
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className='prices'>
                <div className='price'>
                  <span className='dollar'>$</span>
                  <h1 className='tag'>{`${data.prices.monthly[1]}`}</h1>
                  <span className='time'>{`${
                    data.prices.monthly[1] === '1'
                      ? 'for first month'
                      : 'every month'
                  }`}</span>
                  <Button
                    types='submit'
                    click={() =>
                      history.push(`/checkout?id=${data.prices.monthly[0]}`)
                    }
                  >
                    BUY
                  </Button>
                </div>
              </div>
              <div className='prices'>
                <div className='price'>
                  <span className='dollar'>$</span>
                  <h1 className='tag'>{`${data.prices.three[1]}`}</h1>
                  <span className='time'>3 months</span>
                  <Button
                    types='submit'
                    click={() =>
                      history.push(`/checkout?id=${data.prices.three[0]}`)
                    }
                  >
                    BUY
                  </Button>
                </div>
              </div>
              <div className='prices'>
                <div className='price'>
                  <span className='dollar'>$</span>
                  <h1 className='tag'>{`${data.prices.yearly[1]}`}</h1>
                  <span className='time'>every year</span>
                  <Button
                    types='submit'
                    click={() =>
                      history.push(`/checkout?id=${data.prices.yearly[0]}`)
                    }
                  >
                    BUY
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
