import React, { useState } from 'react'
import './book.style.scss'

import { data } from './data'
import Card from '../../components/card/card.component'
import Modal from '../../components/modal/modal.component'

const Book = () => {
  const [modal, setModal] = useState({ title: '', data: {}, toggle: false })

  return (
    <div className='golden'>
      <h1 className='main-title'>Trading Book!</h1>
      {modal.toggle && (
        <Modal
          data={modal.data}
          title={modal.title}
          setModalActive={setModal}
          book
        />
      )}
      <div className='card-container'>
        <Card
          title='Trading Options'
          extra="Expert's Advice"
          data={data.book1}
          modalStatus={setModal}
          book
        />
        <Card
          title='Trading Stocks'
          extra="Expert's Advice"
          data={data.book2}
          modalStatus={setModal}
          book
        />
      </div>
    </div>
  )
}

export default Book
