import React, { useState, useContext } from 'react'
import { fetchFromAPI } from '../../firebase/helper'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
  StripeCardElement,
  StripeCardNumberElement,
  StripeCardElementOptions,
} from '@stripe/stripe-js'
import { useLocation } from 'react-router-dom'
import { names } from './data.js'
import './checkout.style.scss'
import { Formik } from 'formik'
import qs from 'qs'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import Signin from '../../components/signin/signin.component'
import Button from '../../components/button/button.component'
import { AuthContext } from '../../context/context'
import ClipLoader from 'react-spinners/ClipLoader'
import Input from '../../components/input/input.component'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  username: Yup.string().min(3, 'Username is too short').required('Required'),
})

const Checkout: React.FC = () => {
  const id = qs.parse(useLocation().search, { ignoreQueryPrefix: true }).id
  const stripe = useStripe()
  const element = useElements()
  const name = getKeyByValue(names, id)
  const { user } = useContext(AuthContext)
  const [plan, setPlan] = useState<any>(id)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  function getKeyByValue(object: any, value: string) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  //card styles
  const cardElementProperties: StripeCardElementOptions | undefined = {
    iconStyle: 'solid',
    style: {
      base: {
        fontSize: '1.2rem',
        fontSmoothing: 'antialiased',
        iconColor: 'rgb(84, 116, 185)',
        color: 'rgb(41, 72, 138)',
        '::placeholder': {
          color: '#92a2c4',
        },
      },
      invalid: {
        color: 'rgb(154, 75, 173)',
        iconColor: 'rgb(154, 75, 173)',
      },
    },
  }

  // handle the submission of card details
  const handleSubmit = async (email: string, username: string) => {
    setLoading(true)

    const cardElement:
      | StripeCardElement
      | StripeCardNumberElement
      | { token: string }
      | undefined
      | null = element?.getElement(CardElement)

    if (cardElement && stripe) {
      // create Payment Method element
      const { paymentMethod, error }: any = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if (error) {
        alert(error.message)
        setLoading(false)
        return
      }

      const subscription = await fetchFromAPI('subscriptions', {
        body: {
          plan,
          payment_method: paymentMethod.id,
          username,
          email,
        },
      })

      const { latest_invoice } = subscription

      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent

        if (status === 'requires_action') {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          )
          if (confirmationError) {
            console.error(confirmationError)
            alert('unable to confirm card payment')
            return
          }
        }

        //success
        alert('you are subscribed')
        setTimeout(() => {
          history.push('/success')
        }, 3000)
      }
      setLoading(false)
      setPlan(null)
    }
  }

  return (
    <div className='checkout'>
      <h1 className='checkout-title'>Checkout Here for {name}</h1>
      <h3>Step 1</h3>
      {user ? (
        <div className='signed'>
          <h2>Already Signed in!</h2>
        </div>
      ) : (
        <Signin />
      )}
      <h3>Step 2</h3>
      <Formik
        initialValues={{ email: '', username: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values.email, values.username)
            setSubmitting(false)
          }, 100)
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props

          return (
            <form
              className='checkout-form'
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <h1 className='payment'>Pay Here</h1>
              <div>
                <Input
                  id='email'
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  errors={errors.email}
                  touched={touched.email}
                />
                <Input
                  id='username'
                  name='username'
                  type='username'
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.username}
                  touched={touched.username}
                />
              </div>
              <div className='card-container'>
                <CardElement options={cardElementProperties} />
              </div>

              <Button types='submit' disabled={loading || !user}>
                {!loading ? (
                  'subscribe'
                ) : (
                  <ClipLoader color={'#fff'} loading={loading} size={30} />
                )}
              </Button>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Checkout
