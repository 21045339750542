import React, { useState } from 'react'
import Card from '../../components/card/card.component'
import Modal from '../../components/modal/modal.component'
import './main.style.scss'
import { data } from './data.js'
import Diamond from '../../assets/diamond.png'
import Sweep from '../../assets/sweep.png'
import { Link } from 'react-router-dom'

const Main = () => {
  const [modal, setModal] = useState({ title: '', data: {}, toggle: false })

  return (
    <div className='main'>
      <h1 className='main-title'>
        Real Time SMS Premium Service exclusively offered by Wall St Bible LLC
      </h1>
      {modal.toggle && (
        <Modal
          data={modal.data}
          title={modal.title}
          setModalActive={setModal}
        />
      )}
      <div className='card-container'>
        <Card title='Stocks' data={data.stocks} modalStatus={setModal} />
        <Card title='Options' data={data.options} modalStatus={setModal} />
        <Card title='Bundle' data={data.bundle} modalStatus={setModal} />
      </div>

      <div className='button-cont'>
        <div className='cbutton'>
          <Link to='/crypto'>
            <h2 className='title'>Crypto</h2>
            <img className='diamond' src={Diamond} alt='crypto' />
          </Link>
        </div>
        <div className='cbutton'>
          <Link to='/golden'>
            <h2 className='title'>Golden</h2>
            <img className='diamond' src={Sweep} alt='Golden' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Main
