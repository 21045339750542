export const data = {
  golden: {
    prices: {
      monthly: ['price_1JK3OBGDws3elXsYxMIFGPgm', '30.00'],
      three: ['price_1JK3QGGDws3elXsYmxqYt1QS', '75.00'],
      yearly: ['price_1JK3SlGDws3elXsYLjK3XdsJ', '300.00'],
    },
    data: [
      `Get a text message when our realtime database detects
      any bank or individual buying options for more than 1Million dollars in premium. Watch the Big money flow daily! (1 Text Message Per Day)
      `,
    ],
  },
  goldenA: {
    prices: {
      monthly: ['price_1JK3UJGDws3elXsYQ9BTSBlO', '200.00'],
      three: ['price_1JK3W3GDws3elXsYiF1jVc44', '500.00'],
      yearly: ['price_1JK3YaGDws3elXsYa5hVGiOL', '1900.00'],
    },
    data: [
      `Get text messages when our realtime database detects
       any bank or individual buying options for more than 1Million dollars in premium. Watch the Big money flow daily! (4+ Text Messages Per Day)
      `,
    ],
  },
}
