import React, { useContext } from 'react'
import './navbar.style.scss'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../context/context'
import { auth } from '../../firebase/firebase.utils'

const Navbar = () => {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  return (
    <div className='navbar'>
      <div className='logo' onClick={() => history.push('/')}>
        <h1>WallStBible</h1>
        <span>SMS</span>
      </div>
      <ul>
        <li onClick={() => history.push('/')}>Home</li>
        <li onClick={() => history.push('/profile')}>Profile</li>
        {user ? (
          <li onClick={() => auth.signOut()}>Sign-out</li>
        ) : (
          <li onClick={() => history.push('/signin')}>Sign-in</li>
        )}
      </ul>
    </div>
  )
}

export default Navbar
