import React, { useState } from 'react'
import './golden.style.scss'

import { data } from './data'
import Card from '../../components/card/card.component'
import Modal from '../../components/modal/modal.component'

const Golden = () => {
  const [modal, setModal] = useState({ title: '', data: {}, toggle: false })

  return (
    <div className='golden'>
      <h1 className='main-title'>
        Real Time Golden Sweeps SMS Premium Service
      </h1>
      {modal.toggle && (
        <Modal
          data={modal.data}
          title={modal.title}
          setModalActive={setModal}
        />
      )}
      <div className='card-container'>
        <Card
          title='Golden'
          extra='Sweep SILVER'
          data={data.golden}
          modalStatus={setModal}
        />
        <Card
          title='Golden'
          extra='Sweep PLATINUM'
          data={data.goldenA}
          modalStatus={setModal}
        />
      </div>
    </div>
  )
}

export default Golden
