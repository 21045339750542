import React from 'react'
import Button from '../button/button.component'
import './card.style.scss'

interface props {
  modalStatus: React.Dispatch<
    React.SetStateAction<{
      title: string
      data: {}
      toggle: boolean
    }>
  >
  title: string
  data: {
    prices: {
      monthly?: string[]
      three?: string[]
      yearly?: string[]
      day?: string[]
    }
    data: string[]
  }
  extra?: string
  book?: boolean
}

const Card: React.FC<props> = ({ modalStatus, title, data, extra, book }) => {
  return (
    <div className='card'>
      <div
        className='title'
        onClick={() => modalStatus({ title: title, data: data, toggle: true })}
      >
        <h1>{title}</h1>
        <span className='top-mess'>
          {book ? `Exclusive Book` : `TEXT Messages`}
        </span>
        {extra && <span className='bottom-mess'>{extra}</span>}
      </div>
      <div className='content'>
        <ul>
          {data.data.map((item, index) => {
            return <li key={index}>{item}</li>
          })}
        </ul>
        <Button
          types='submit'
          click={() => modalStatus({ title: title, data: data, toggle: true })}
        >
          Know more
        </Button>
      </div>
    </div>
  )
}

export default Card
