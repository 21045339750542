import React from 'react'
import './button.style.scss'

interface props {
  types: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  children: React.ReactNode
  quality?: boolean | undefined
  click?: () => void | undefined | Promise<void>
  price?: string | undefined
  clicked?: (_: string | undefined) => void | undefined
}

const Button: React.FC<props> = ({
  types,
  disabled,
  children,
  quality,
  click,
  price,
  clicked,
}) => {
  return (
    <button
      type={types}
      disabled={disabled}
      className={`${quality ? 'prem' : ''} custom_button`}
      onClick={
        clicked
          ? () => {
              clicked(price)
            }
          : click
      }
    >
      {children}
    </button>
  )
}

export default Button
