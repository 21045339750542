export const data = {
  book1: {
    prices: {
      day: ['price_1KWxbqGDws3elXsYNW2N2T22', '1.00'],
    },
    data: [
      `Get our Exclusive Book to learn about Options!
      `,
    ],
  },
  book2: {
    prices: {
      day: ['price_1KWy41GDws3elXsYf1c5hXXs', '1.00'],
    },
    data: [
      `Get our Exclusive Book to learn about Stocks!
      `,
    ],
  },
}
