import React, { useEffect, useState } from 'react'
import { auth, firestore } from '../firebase/firebase.utils'
import firebase from 'firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import ClipLoader from 'react-spinners/ClipLoader'
import './context.style.scss'

interface IContextProps {
  user: firebase.User | null
  plans: Array<any>
}

export const AuthContext = React.createContext({} as IContextProps)

const AuthProvider = ({ children }: any | null) => {
  const [user, loadingauth, errorauth] = useAuthState(auth)
  let ref = null
  if (user) {
    ref = firestore.collection('users').doc(user?.uid)
  }
  const [value, loading, error] = useDocumentData<any>(ref)

  if (loadingauth) {
    return (
      <div className='loader'>
        <ClipLoader color={'#26667a'} loading={loading} size={150} />
      </div>
    )
  } else if (loading) {
    return (
      <div className='loader'>
        <ClipLoader color={'#26667a'} loading={loading} size={150} />
      </div>
    )
  } else {
    // if (!value && user) {
    //   return <>Loading...</>
    // }
    return (
      <AuthContext.Provider
        value={{
          user: user,
          plans: value && value.activePlans ? value.activePlans : null,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

export default AuthProvider
