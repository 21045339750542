export const names = {
  'Options Monthly for $25.99': 'price_1KU3fqGDws3elXsY8uzg06pl',
  'Options Three Months for $65.99': 'price_1KU3h4GDws3elXsYVOSq6c2H',
  'Options Yearly for $249.99': 'price_1KU3ldGDws3elXsYDbrlaiO0',
  'Stocks Monthly for $25.99': 'price_1KU36VGDws3elXsYqDjdoAzK',
  'Stocks Three Months for $65.99': 'price_1KU3BDGDws3elXsYObGtr7Dr',
  'Stocks Yearly for $249.99': 'price_1KU3e6GDws3elXsYNApzKOon',
  'Bundle Monthly for $49.99': 'price_1KU3nBGDws3elXsY9HA5SzAO',
  'Bundle Three Months for $119.99': 'price_1KU3oQGDws3elXsYZJ1RFdgf',
  'Bundle Yearly for $399.99': 'price_1KU3q6GDws3elXsYfkRbWN5u',
  'Crypto Monthly for $25.99': 'price_1KU451GDws3elXsYyS4ogQqs',
  'Crypto Three Months for $65.99': 'price_1KU465GDws3elXsYNfRYCgHP',
  'Crypto Yearly for $249.99': 'price_1KU47qGDws3elXsYFiCmJCFE',
  'Crypto & Stocks Monthly for $49.99': 'price_1KU49CGDws3elXsYK5V3Zh5p',
  'Crypto & Stocks Three Months for $199.99': 'price_1KU4AcGDws3elXsYJv6rPveO',
  'Crypto & Stocks Yearly for $399.99': 'price_1KU4C6GDws3elXsYpuv7UjSp',
  'Crypto & Options Monthly for $49.99': 'price_1KU4H5GDws3elXsY1SAj0ORk',
  'Crypto & Options Three Months for $199.99': 'price_1KU4IMGDws3elXsYWDH4DCKo',
  'Crypto & Options Yearly for $399.99': 'price_1KU4JmGDws3elXsY5i2Z2mIo',
  'Crypto & Bundle Monthly for $59.99': 'price_1KU3s9GDws3elXsYRtOayHZA',
  'Crypto & Bundle Three Months for $129.99': 'price_1KU3tWGDws3elXsYlipZ7K4l',
  'Crypto & Bundle Yearly for $484.99': 'price_1KU3v0GDws3elXsYYWGwfzz5',

  'SMS Golden Sweeps SILVER Monthly for $30.00':
    'price_1JK3OBGDws3elXsYxMIFGPgm',
  'SMS Golden Sweeps SILVER Three Month for $75.00':
    'price_1JK3QGGDws3elXsYmxqYt1QS',
  'SMS Golden Sweeps SILVER Yearly for $300.00':
    'price_1JK3SlGDws3elXsYLjK3XdsJ',
  'SMS Golden Sweep PLATINUM  Monthly for $200.00':
    'price_1JK3UJGDws3elXsYQ9BTSBlO',
  'SMS Golden Sweep PLATINUM  Three Month for $500.00':
    'price_1JK3W3GDws3elXsYiF1jVc44',
  'SMS Golden Sweep PLATINUM  Yearly for $ 1900.00':
    'price_1JK3YaGDws3elXsYa5hVGiOL',

  "Trading Book - Expert's Advice Options for $1.00":
    'price_1KWxbqGDws3elXsYNW2N2T22',
  "Trading Book - Expert's Advice Stocks for $1.00":
    'price_1KWy41GDws3elXsYf1c5hXXs',
}
