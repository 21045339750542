export const data = {
  options: {
    prices: {
      monthly: ['price_1KU3fqGDws3elXsY8uzg06pl', '25.99'], //$25.99
      three: ['price_1KU3h4GDws3elXsYVOSq6c2H', '65.99'],
      yearly: ['price_1KU3ldGDws3elXsYDbrlaiO0', '249.99'],
    },
    data: [
      'Real TIme Options Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
  stocks: {
    prices: {
      monthly: ['price_1KU36VGDws3elXsYqDjdoAzK', '25.99'], // $25.99
      three: ['price_1KU3BDGDws3elXsYObGtr7Dr', '65.99'],
      yearly: ['price_1KU3e6GDws3elXsYNApzKOon', '249.99'],
    },
    data: [
      'Real TIme Stocks Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
  bundle: {
    prices: {
      monthly: ['price_1KU3nBGDws3elXsY9HA5SzAO', '49.99'], // 49.99
      three: ['price_1KU3oQGDws3elXsYZJ1RFdgf', '119.99'],
      yearly: ['price_1KU3q6GDws3elXsYfkRbWN5u', '399.99'],
    },
    data: [
      'Real TIme Stocks & Options Signals Directly to Your Phone',
      'Gives the Analyst Name',
      'Buy Entry',
      'Sell Entry',
      'LIVE Signals',
      'Text Notifications',
      'WORLDWIDE Service',
    ],
  },
}
